import type { FC, ReactNode } from "react";
import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  ApiProvider,
  AppearanceModeController,
  AuthenticationErrorPage,
  AuthenticationProvider,
  ConfigProvider,
  DatadogProvider,
  ErrorBoundaryReloadOnDynamicImport,
  FeatureFlagProvider,
  ForbiddenErrorPage,
  MaintenanceModeGate,
  PageLoader,
  RequireAuth,
  StordChakraProvider,
  IdentityProvider,
  useConfig,
  useFlag,
  useIsAdmin,
  LayoutWrapper,
} from "@stordco/fe-components";

import {
  ConnectionRedirects,
  NetworkNavigation,
  RedirectUser,
} from "./navigation";
import { OrderDetailsRedirect } from "../features/OrderDetails/OrderDetailsRedirect";
import { useNetworkConfigurationFlag, NetworkProvider } from "./Network";
import { GlobalSearchProvider } from "./GlobalSearchProvider";
import InstalledConnections from "../features/Connections/InstalledConnections";
import Dashboard from "../features/Dashboard/Dashboard";
import { FulfillmentDashboard } from "../features/Dashboard/Fulfillment/FulfillmentDashboard";
import { NetworkPermissionsProvider } from "./NetworkPermissionsProvider";
import { UserflowInitializer } from "./UserflowInitializer";
import { SEO } from "./SEO";
import {
  LaunchDarklyIdentifier,
  LaunchDarklyIdentifierWithNetwork,
} from "./LaunchDarklyIdentifier";
import { AdminKeyboardShortcutController } from "./AdminKeyboardShortcutController";
import { DatadogUserSetter } from "@stordco/fe-components/src/components/Datadog/DatadogUserSetter";

const ForecastIndex = lazy(
  () => import("../features/InventoryPlanning/ForecastIndex"),
);
const HistoricalsIndex = lazy(
  () => import("../features/InventoryPlanning/HistoryIndex"),
);

const StorefrontIndex = lazy(
  () => import("../features/ConsumerExperience/Storefronts/StorefrontsIndex"),
);

const StorefrontDetails = lazy(
  () => import("../features/ConsumerExperience/Storefronts/StorefrontDetails"),
);

const EmailAlertsIndex = lazy(
  () => import("../features/ConsumerExperience/EmailAlerts/EmailAlertsIndex"),
);
const EmailAlerts = lazy(
  () => import("../features/ConsumerExperience/EmailAlerts/Alerts"),
);
const EmailAlertConfig = lazy(
  () => import("../features/ConsumerExperience/EmailAlerts/AlertConfig"),
);
const EmailAlertsBranding = lazy(
  () => import("../features/ConsumerExperience/EmailAlerts/Branding"),
);
const TrackingPortalIndex = lazy(() =>
  import("../features/ConsumerExperience/TrackingPortal").then(
    ({ TrackingPortalIndex }) => ({
      default: TrackingPortalIndex,
    }),
  ),
);
const TrackingPortal = lazy(() =>
  import("../features/ConsumerExperience/TrackingPortal").then(
    ({ Portals }) => ({
      default: Portals,
    }),
  ),
);
const TrackingPortalBranding = lazy(() =>
  import("../features/ConsumerExperience/TrackingPortal").then(
    ({ Branding }) => ({
      default: Branding,
    }),
  ),
);
const Claims = lazy(
  () => import("../features/ConsumerExperience/Claims/Claims"),
);

const Members = lazy(() => import("../features/CloudUserManagement/Members"));
const Member = lazy(() => import("../features/CloudUserManagement/Member"));
const MemberProfile = lazy(
  () => import("../features/CloudUserManagement/MemberProfile"),
);
const MemberPermissions = lazy(
  () => import("../features/CloudUserManagement/MemberPermissions"),
);
const NotFoundErrorPage = lazy(() => import("./ErrorPage/NotFoundErrorPage"));
const ExploreConnections = lazy(
  () => import("../features/Connections/ExploreConnections"),
);
const OrderIndex = lazy(() => import("../features/Orders/OrderIndex"));
const SalesOrderDetails = lazy(() =>
  import("../features/OrderDetails/sales/SalesOrderDetails").then(
    ({ SalesOrderDetails }) => ({ default: SalesOrderDetails }),
  ),
);
const SalesOrderDetailsIndex = lazy(() =>
  import("../features/OrderDetails/sales/SalesOrderDetailsIndex").then(
    ({ SalesOrderDetailsIndex }) => ({ default: SalesOrderDetailsIndex }),
  ),
);
const OrderShipments = lazy(() =>
  import("../features/OrderDetails/shared/OrderShipments").then(
    ({ OrderShipments }) => ({ default: OrderShipments }),
  ),
);
const PurchaseOrderDetails = lazy(
  () => import("../features/OrderDetails/purchase/PurchaseOrderDetails"),
);
const PurchaseOrderDetailsIndex = lazy(() =>
  import("../features/OrderDetails/purchase/PurchaseOrderDetailsIndex").then(
    ({ PurchaseOrderDetailsIndex }) => ({ default: PurchaseOrderDetailsIndex }),
  ),
);
const OrderDocuments = lazy(() =>
  import("../features/OrderDetails/shared/OrderDocuments").then(
    ({ OrderDocuments }) => ({ default: OrderDocuments }),
  ),
);
const PurchaseOrderInboundShipments = lazy(() =>
  import(
    "../features/OrderDetails/PurchaseOrderWithInboundsDetails/PurchaseOrderInboundShipments"
  ).then(({ PurchaseOrderInboundShipments }) => ({
    default: PurchaseOrderInboundShipments,
  })),
);
const OrderReceipts = lazy(() =>
  import("../features/OrderDetails/shared/OrderReceipts").then(
    ({ OrderReceipts }) => ({ default: OrderReceipts }),
  ),
);
const TransferOrderDetails = lazy(() =>
  import("../features/OrderDetails/transfer/TransferOrderDetails").then(
    ({ TransferOrderDetails }) => ({ default: TransferOrderDetails }),
  ),
);
const TransferOrderDetailsIndex = lazy(() =>
  import("../features/OrderDetails/transfer/TransferOrderDetailsIndex").then(
    ({ TransferOrderDetailsIndex }) => ({ default: TransferOrderDetailsIndex }),
  ),
);
const SalesOrderForm = lazy(
  () => import("../features/SalesOrderForm/SalesOrderForm"),
);
const PurchaseOrderForm = lazy(() => import("../features/PurchaseOrderForm"));
const TransferOrderForm = lazy(
  () => import("../features/TransferOrderForm/TransferOrderForm"),
);
const FacilityActivityDetails = lazy(
  () => import("../features/FacilityActivityDetails"),
);
const OverviewPanel = lazy(() =>
  import("../features/FacilityActivityDetails/panels").then(
    ({ OverviewPanel }) => ({ default: OverviewPanel }),
  ),
);
const FacilityActivityShipments = lazy(() =>
  import("../features/FacilityActivityDetails/panels").then(
    ({ FacilityActivityShipments }) => ({ default: FacilityActivityShipments }),
  ),
);
const FacilityActivityDocuments = lazy(() =>
  import("../features/FacilityActivityDetails/panels").then(
    ({ FacilityActivityDocuments }) => ({ default: FacilityActivityDocuments }),
  ),
);
const WorkOrdersIndex = lazy(() => import("../features/WorkOrders"));
const MadeToStockKitsTab = lazy(
  () => import("../features/WorkOrders/views/MadeToStockKitsTab"),
);
const ProductCatalog = lazy(() =>
  import("../features/ProductCatalog/ProductCatalog").then(
    ({ ProductCatalog }) => ({ default: ProductCatalog }),
  ),
);
const ProductCatalogIndex = lazy(
  () =>
    import(
      "../features/ProductCatalog/ProductCatalogIndex/ProductCatalogIndex"
    ),
);
const LotControl = lazy(() => import("../features/LotControl/LotControl"));
const BundleDetails = lazy(
  () => import("../features/ProductCatalog/Details/BundleDetails"),
);
const ItemDetails = lazy(
  () => import("../features/ProductCatalog/Details/ItemDetails"),
);
const ProductListings = lazy(
  () => import("../features/ProductCatalog/ProductListings/ProductListings"),
);
const ListingDetails = lazy(
  () => import("../features/ProductCatalog/ProductListings/ListingDetails"),
);
const Inventory = lazy(() => import("../features/Inventory/Inventory"));
const NetworkOverview = lazy(
  () => import("../features/Inventory/views/NetworkOverview"),
);
const FacilityLevels = lazy(
  () => import("../features/Inventory/views/FacilityLevels"),
);
const ChannelLevels = lazy(
  () => import("../features/Inventory/views/ChannelLevels"),
);
const Lot = lazy(() => import("../features/Inventory/views/Lot"));
const InventoryPlanning = lazy(() => import("../features/InventoryPlanning"));
const InventoryPlanningMarketingPage = lazy(
  () => import("./Marketing/InventoryPlanning/InventoryPlanningMarketingPage"),
);
const NetworkFacilities = lazy(() =>
  import("../features/NetworkDetails/Facilities/NetworkFacilities").then(
    ({ NetworkFacilities }) => ({ default: NetworkFacilities }),
  ),
);
const NetworkServiceMethodsPanel = lazy(() =>
  import("../features/NetworkDetails/Carriers/NetworkServiceMethodsPanel").then(
    ({ NetworkServiceMethodsPanel }) => ({
      default: NetworkServiceMethodsPanel,
    }),
  ),
);
const FacilityDetails = lazy(
  () =>
    import(
      "../features/NetworkDetails/Facilities/FacilityDetails/FacilityDetails"
    ),
);
const Suppliers = lazy(
  () => import("../features/NetworkDetails/Suppliers/SuppliersIndex"),
);
const SupplierDetails = lazy(() =>
  import("../features/NetworkDetails/Suppliers/SupplierDetails").then(
    ({ SupplierDetails }) => ({ default: SupplierDetails }),
  ),
);
const Connections = lazy(() => import("../features/Connections/Connections"));
const ConnectionDetails = lazy(
  () =>
    import(
      "../features/Connections/ConnectionManagement/common/ConnectionDetails"
    ),
);
const OrderHoldsPage = lazy(
  () => import("../components/Marketing/OrderHolds/OrderHoldsPage"),
);
const Insights = lazy(() => import("../features/Insights"));
const UserProfile = lazy(() => import("../features/UserProfile/UserProfile"));
const Configuration = lazy(() => import("../features/Configuration"));
const CompanyConfiguration = lazy(
  () => import("../features/Configuration/Company/CompanyConfiguration"),
);
const InventoryConfiguration = lazy(
  () => import("../features/Configuration/Inventory/InventoryConfiguration"),
);
const Data = lazy(() => import("../features/Data/Data"));
const UnitsOfMeasure = lazy(
  () => import("../features/Data/UnitsOfMeasure/UnitsOfMeasure"),
);
const ActivityLog = lazy(() => import("../features/ActivityLog"));
const Reservations = lazy(
  () => import("../features/Reservations/Reservations"),
);
const MultichannelInventoryPage = lazy(
  () => import("./Marketing/MultichannelInventory/MultichannelInventoryPage"),
);
const ConsumerExperiencePage = lazy(
  () => import("./Marketing/ConsumerExperience/ConsumerExperiencePage"),
);
const ApiKeyManagement = lazy(
  () => import("../features/ApiKeyManagement/ApiKeyManagement"),
);
const OrderRoutingPage = lazy(
  () => import("./Marketing/OrderRouting/OrderRoutingPage"),
);
const OrderModificationPage = lazy(
  () => import("./Marketing/OrderModificationPage"),
);
const BackorderBehaviorPage = lazy(
  () => import("./Marketing/BackorderBehaviorPage"),
);

const CloudFeatureGate = lazy(
  () => import("./CloudFeatureGate/CloudFeatureGate"),
);
const InventoryDashboard = lazy(
  () => import("../features/Dashboard/Inventory"),
);
const WebhooksPortal = lazy(
  () => import("../features/WebhooksPortal/WebhooksPortal"),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ErrorElement: FC = function ErrorElement() {
  throw useRouteError();
};

const router = createBrowserRouter([
  {
    path: "auth_error",
    element: <AuthenticationErrorPage />,
  },
  {
    path: "forbidden",
    element: <ForbiddenErrorPage outsideNavigation />,
  },
  {
    path: "not_found",
    element: <NotFoundErrorPage outsideNavigation />,
  },
  {
    path: "connect/:connectionName",
    element: (
      <Crust>
        <ConnectionRedirects />
      </Crust>
    ),
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: <RedirectUser />,
      },
      {
        path: "distribution_networks/:id",
        element: (
          <NetworkPermissionsProvider>
            <NetworkProvider>
              <SEO />
              <UserflowInitializer />

              <LaunchDarklyIdentifierWithNetwork>
                <GlobalSearchProvider>
                  <NetworkNavigation>
                    <LayoutWrapper>
                      <Suspense fallback={<PageLoader />}>
                        <Outlet />
                      </Suspense>
                    </LayoutWrapper>
                  </NetworkNavigation>
                </GlobalSearchProvider>
              </LaunchDarklyIdentifierWithNetwork>
            </NetworkProvider>
          </NetworkPermissionsProvider>
        ),
        children: [
          { index: true, element: <Navigate to="dashboard" replace /> },
          {
            path: "dashboard",
            element: <Dashboard />,
            children: [
              { index: true, element: <FulfillmentDashboard /> },
              {
                path: "inventory",
                element: <InventoryDashboard />,
              },
            ],
          },
          {
            path: "orders",
            children: [
              { index: true, element: <OrderIndex /> },
              {
                path: "sales",
                children: [
                  {
                    path: ":orderId",
                    element: <SalesOrderDetails />,
                    children: [
                      { index: true, element: <SalesOrderDetailsIndex /> },
                      { path: "shipments", element: <OrderShipments /> },
                    ],
                  },
                ],
              },
              {
                path: "purchase_with_inbounds",
                children: [
                  {
                    path: ":orderId",
                    async lazy() {
                      const { PurchaseOrderWithInboundsDetails } = await import(
                        "../features/OrderDetails/PurchaseOrderWithInboundsDetails"
                      );

                      return {
                        Component: PurchaseOrderWithInboundsDetails,
                      };
                    },
                    children: [
                      {
                        index: true,
                        async lazy() {
                          const { PurchaseOrderWithInboundsDetailsIndex } =
                            await import(
                              "../features/OrderDetails/PurchaseOrderWithInboundsDetails"
                            );

                          return {
                            Component: PurchaseOrderWithInboundsDetailsIndex,
                          };
                        },
                      },
                      {
                        path: "shipments",
                        element: <PurchaseOrderInboundShipments />,
                      },
                      { path: "documents", element: <OrderDocuments /> },
                      { path: "receipts", element: <OrderReceipts /> },
                    ],
                  },
                ],
              },
              {
                path: "purchase",
                children: [
                  {
                    path: ":orderId",
                    element: <PurchaseOrderDetails />,
                    children: [
                      { index: true, element: <PurchaseOrderDetailsIndex /> },
                      { path: "documents", element: <OrderDocuments /> },
                      { path: "receipts", element: <OrderReceipts /> },
                    ],
                  },
                ],
              },
              {
                path: "transfer",
                children: [
                  {
                    path: ":orderId",
                    element: <TransferOrderDetails />,
                    children: [
                      { index: true, element: <TransferOrderDetailsIndex /> },
                      { path: "documents", element: <OrderDocuments /> },
                      { path: "shipments", element: <OrderShipments /> },
                      { path: "receipts", element: <OrderReceipts /> },
                    ],
                  },
                ],
              },
              {
                path: "create_sales_order",
                element: <SalesOrderForm />,
              },
              {
                path: "create_purchase_order",
                element: <PurchaseOrderForm />,
              },
              {
                path: "create_transfer_order",
                element: <TransferOrderForm />,
              },
              {
                path: ":orderId/*",
                element: <OrderDetailsRedirect />,
              },
            ],
          },
          {
            path: "facility_activities/:facilityActivityId",
            element: <FacilityActivityDetails />,
            children: [
              { index: true, element: <OverviewPanel /> },
              { path: "shipments", element: <FacilityActivityShipments /> },
              { path: "documents", element: <FacilityActivityDocuments /> },
            ],
          },
          {
            path: "work_orders",
            element: <WorkOrdersIndex />,
            children: [
              { index: true, element: <MadeToStockKitsTab /> },
              { path: "m2s_kits", element: <MadeToStockKitsTab /> },
            ],
          },
          {
            path: "product_catalog/listings/:listingId",
            element: <ListingDetails />,
          },
          {
            path: "product_catalog",
            element: <ProductCatalog />,
            children: [
              { index: true, element: <ProductCatalogIndex /> },
              { path: "listings", element: <ProductListings /> },
            ],
          },
          {
            path: "inventory",
            element: <Inventory />,
            children: [
              { index: true, element: <NetworkOverview /> },
              { path: "facility", element: <FacilityLevels /> },
              { path: "channel", element: <ChannelLevels /> },
              { path: "lot", element: <Lot /> },
            ],
          },
          {
            path: "lot_control",
            element: <LotControl />,
          },
          {
            path: "planning",
            element: <InventoryPlanningRoute />,
            children: [
              {
                index: true,
                element: <ForecastIndex />,
              },
              {
                path: "history",
                element: <HistoricalsIndex />,
              },
            ],
          },
          {
            path: "inventory_adjustments",
            async lazy() {
              const { InventoryAdjustments } = await import(
                "../features/InventoryAdjustments"
              );
              return { Component: InventoryAdjustments };
            },
            children: [
              {
                index: true,
                async lazy() {
                  const { InventoryAdjustmentsIndex } = await import(
                    "../features/InventoryAdjustments"
                  );
                  return { Component: InventoryAdjustmentsIndex };
                },
              },
            ],
          },
          {
            path: "network/facilities",
            element: <NetworkFacilities />,
          },
          {
            path: "network/facilities/:tradeConnectionId",
            element: <FacilityDetails />,
          },
          {
            path: "network/service-methods",
            element: <NetworkServiceMethodsPanel />,
          },
          {
            path: "network/suppliers",
            element: <Suppliers />,
          },
          {
            path: "network/suppliers/:supplierId",
            element: <SupplierDetails />,
          },
          {
            path: "channel_management/multichannel",
            element: <MultiChannelRoute />,
          },
          {
            path: "product_catalog/bundles/:bundleId",
            element: <BundleDetails />,
          },
          { path: "product_catalog/items/:sku", element: <ItemDetails /> },
          {
            path: "consumer_experience",
            children: [
              {
                index: true,
                element: <ConsumerExperienceRoute />,
              },
              {
                path: "pre-purchase",
                element: <Outlet />,
                children: [
                  { index: true, element: <StorefrontIndex /> },
                  {
                    path: "storefronts/:storefrontId",
                    element: <StorefrontDetails />,
                  },
                ],
              },
              {
                path: "email_alerts",
                element: <EmailAlertsIndex />,
                children: [
                  { index: true, element: <EmailAlerts /> },
                  { path: "branding", element: <EmailAlertsBranding /> },
                ],
              },
              {
                path: "email_alerts/:alertTypeId",
                element: <EmailAlertConfig />,
              },
              {
                path: "email_alerts/:alertTypeId/templates/:status",
                element: <EmailAlertConfig />,
              },
              {
                path: "tracking_portals",
                element: <TrackingPortalIndex />,
                children: [
                  { index: true, element: <TrackingPortal /> },
                  { path: "branding", element: <TrackingPortalBranding /> },
                ],
              },
              {
                path: "shipment_protection",
                element: <Claims />,
              },
            ],
          },
          {
            path: "automation",
            children: [
              {
                index: true,
                async lazy() {
                  const { AutomationIndex } = await import(
                    "../features/Automation"
                  );

                  return { Component: AutomationIndex };
                },
              },
              {
                path: ":automationGroup/rule/:automationId",
                async lazy() {
                  const { AutomationForm } = await import(
                    "../features/Automation"
                  );

                  return { Component: AutomationForm };
                },
              },
              {
                path: ":automationGroup",

                async lazy() {
                  const { AutomationGroup } = await import(
                    "../features/Automation"
                  );

                  return { Component: AutomationGroup };
                },

                children: [
                  {
                    index: true,

                    async lazy() {
                      const { AutomationRules } = await import(
                        "../features/Automation"
                      );

                      return { Component: AutomationRules };
                    },
                  },
                  {
                    path: "templates",

                    async lazy() {
                      const { AutomationTemplates } = await import(
                        "../features/Automation"
                      );

                      return { Component: AutomationTemplates };
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "connections",
            element: <Connections />,
            children: [
              { index: true, element: <InstalledConnections /> },
              { path: "explore", element: <ExploreConnections /> },
            ],
          },
          {
            path: "connections/:integrationId",
            element: <ConnectionDetails />,
          },
          {
            path: "order_holds",
            element: <OrderHoldsPage />,
          },
          { path: "order_routing", element: <OrderRoutingPage /> },
          {
            path: "order_modification",
            element: <OrderModificationPage />,
          },
          {
            path: "backorder_behavior",
            element: <BackorderBehaviorPage />,
          },
          {
            path: "insights",
            element: <Insights />,
          },
          {
            path: "settings",
            children: [
              { path: "profile", element: <UserProfile /> },
              {
                path: "members",
                element: (
                  <CloudFeatureGate
                    cloudAdminRedirectPath={({ tenant_id, network_id }) =>
                      `/admin/organizations/${tenant_id}/members?resource_path=oms/networks/${network_id}`
                    }
                  >
                    <Members />
                  </CloudFeatureGate>
                ),
              },
              {
                path: "members/:memberId",
                element: (
                  <CloudFeatureGate
                    cloudAdminRedirectPath={({ tenant_id, network_id }) =>
                      `/admin/organizations/${tenant_id}/members?resource_path=oms/networks/${network_id}`
                    }
                  >
                    <Member />
                  </CloudFeatureGate>
                ),
                children: [
                  { index: true, Component: MemberProfile },
                  { path: "permissions", Component: MemberPermissions },
                ],
              },
              {
                path: "config",
                element: <Configuration />,
                children: [
                  { index: true, element: <CompanyConfiguration /> },
                  { path: "inventory", element: <InventoryConfiguration /> },
                ],
              },
              {
                path: "data",
                element: <Data />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="units_of_measure" replace />,
                  },
                  { path: "units_of_measure", element: <UnitsOfMeasure /> },
                ],
              },
              {
                path: "activity_log",
                element: <ActivityLog />,
              },
              {
                path: "api_keys",
                element: (
                  <CloudFeatureGate
                    cloudAdminRedirectPath={({ tenant_id }) =>
                      `/admin/organizations/${tenant_id}/api-keys`
                    }
                  >
                    <ApiKeyManagement />
                  </CloudFeatureGate>
                ),
              },
              {
                path: "webhooks",
                element: <WebhooksPortal />,
              },
            ],
          },
          {
            path: "*",
            element: <NotFoundErrorPage />,
          },
        ],
      },
      {
        path: "admin",
        async lazy() {
          const { AdminNavigationProvider } = await import("../features/Admin");

          return {
            element: (
              <RequireIsAdmin>
                <AdminNavigationProvider>
                  <LaunchDarklyIdentifier>
                    <LayoutWrapper>
                      <Outlet />
                    </LayoutWrapper>
                  </LaunchDarklyIdentifier>
                </AdminNavigationProvider>
              </RequireIsAdmin>
            ),
          };
        },
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={{
                  pathname: "shippers",
                  search: "filter[status]=active",
                }}
              />
            ),
          },
          {
            path: "shippers",
            children: [
              {
                index: true,
                async lazy() {
                  const { ShippersIndex } = await import("../features/Admin");

                  return {
                    Component: ShippersIndex,
                  };
                },
              },
              {
                path: ":networkId",
                children: [
                  {
                    index: true,
                    element: <Navigate to="configuration" replace />,
                  },
                  {
                    path: "configuration",
                    async lazy() {
                      const { AdminNetworkConfiguration } = await import(
                        "../features/Admin"
                      );

                      return {
                        Component: AdminNetworkConfiguration,
                      };
                    },
                    children: [
                      {
                        index: true,
                        async lazy() {
                          const { BaseNetworkConfiguration } = await import(
                            "../features/Admin"
                          );

                          return {
                            Component: BaseNetworkConfiguration,
                          };
                        },
                      },
                      {
                        path: "add-on",
                        async lazy() {
                          const { AddOnNetworkConfiguration } = await import(
                            "../features/Admin"
                          );

                          return {
                            Component: AddOnNetworkConfiguration,
                          };
                        },
                      },
                      {
                        path: "channels",
                        async lazy() {
                          const { Channels } = await import(
                            "../features/Admin"
                          );

                          return {
                            Component: Channels,
                          };
                        },
                      },
                    ],
                  },
                  {
                    path: "support",
                    async lazy() {
                      const { SupportTasks } = await import(
                        "../features/Admin"
                      );

                      return {
                        Component: SupportTasks,
                      };
                    },
                    children: [
                      {
                        path: "inventory",
                        async lazy() {
                          const { InventorySupportTasks } = await import(
                            "../features/Admin"
                          );

                          return {
                            Component: InventorySupportTasks,
                          };
                        },
                      },
                      {
                        path: "product_catalog",
                        async lazy() {
                          const { ProductCatalogSupportTasks } = await import(
                            "../features/Admin"
                          );

                          return {
                            Component: ProductCatalogSupportTasks,
                          };
                        },
                      },
                      {
                        path: "orders",
                        async lazy() {
                          const { OrderSupportTasks } = await import(
                            "../features/Admin"
                          );

                          return {
                            Component: OrderSupportTasks,
                          };
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "facilities",
            children: [
              {
                index: true,
                async lazy() {
                  const { FacilityIndex } = await import("../features/Admin");

                  return {
                    Component: FacilityIndex,
                  };
                },
              },
              {
                path: ":facilityId",
                async lazy() {
                  const { FacilityDetails } = await import("../features/Admin");

                  return {
                    Component: FacilityDetails,
                  };
                },
                children: [
                  {
                    index: true,
                    async lazy() {
                      const { DetailsPanel } = await import(
                        "../features/Admin"
                      );

                      return {
                        Component: DetailsPanel,
                      };
                    },
                  },
                  {
                    path: "shippers",
                    async lazy() {
                      const { ShippersPanel } = await import(
                        "../features/Admin"
                      );

                      return {
                        Component: ShippersPanel,
                      };
                    },
                  },
                  {
                    path: "contacts",
                    async lazy() {
                      const { ContactsPanel } = await import(
                        "../features/Admin"
                      );

                      return {
                        Component: ContactsPanel,
                      };
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "settings",
            children: [
              {
                path: "reason-codes",
                async lazy() {
                  const { ReasonCodes } = await import("../features/Admin");

                  return {
                    Component: ReasonCodes,
                  };
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundErrorPage outsideNavigation />,
  },
]);

export function App() {
  return (
    <StordChakraProvider>
      <ErrorBoundaryReloadOnDynamicImport>
        <Suspense fallback={<PageLoader />}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools
                position="bottom"
                buttonPosition="bottom-right"
              />

              <ConfigProvider>
                {(config) => (
                  <DatadogProvider
                    version={__APP_VERSION__}
                    applicationId={config.DATADOG_APPLICATION_ID}
                    clientToken={config.DATADOG_CLIENT_TOKEN}
                    service={config.DATADOG_SERVICE_NAME}
                    env={config.DATADOG_ENV}
                  >
                    <Helmet
                      titleTemplate="%s | Stord OMS"
                      defaultTitle="Stord OMS"
                    />

                    <RouterProvider router={router} />
                  </DatadogProvider>
                )}
              </ConfigProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </Suspense>
      </ErrorBoundaryReloadOnDynamicImport>
    </StordChakraProvider>
  );
}

function Crust({ children }: { children: ReactNode }) {
  const config = useConfig();

  return (
    <AuthenticationProvider
      auth0Domain={config.AUTH0_DOMAIN}
      auth0ClientId={config.AUTH0_CLIENT_ID_FE}
      auth0Audience={config.AUTH0_AUDIENCE}
    >
      <RequireAuth>
        <ApiProvider baseUrl={config.API_HOST} cloudUrl={config.CLOUD_HOST}>
          <FeatureFlagProvider
            clientId={config.LD_CLIENT_ID}
            overrides={config.FEATURE_FLAG_OVERRIDES}
          >
            <MaintenanceModeGate>
              <IdentityProvider>
                <DatadogUserSetter />

                {children}
              </IdentityProvider>
            </MaintenanceModeGate>
          </FeatureFlagProvider>
        </ApiProvider>
      </RequireAuth>
    </AuthenticationProvider>
  );
}

function Root() {
  return (
    <Crust>
      <AppearanceModeController />

      <AdminKeyboardShortcutController />

      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </Crust>
  );
}

function ConsumerExperienceRoute() {
  const hasConsumerExperienceFeature = useNetworkConfigurationFlag(
    "consumer_experience",
  );
  const forceShowCXTypeform = useFlag(
    "force-show-consumer-experience-typeform",
  );

  if (hasConsumerExperienceFeature && !forceShowCXTypeform) {
    return <Navigate to="pre-purchase" replace />;
  }

  return <ConsumerExperiencePage />;
}

function MultiChannelRoute() {
  const channelReservationsFlag = useNetworkConfigurationFlag(
    "channel_reservations",
  );

  if (channelReservationsFlag) {
    return <Reservations />;
  }

  return <MultichannelInventoryPage />;
}

function InventoryPlanningRoute() {
  const inventoryPlanningFlag =
    useNetworkConfigurationFlag("demand_planning_ui");
  const isAdmin = useIsAdmin();

  if (inventoryPlanningFlag || isAdmin) {
    return <InventoryPlanning />;
  }

  return <InventoryPlanningMarketingPage />;
}

function RequireIsAdmin({ children }: { children: ReactNode }) {
  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    return <NotFoundErrorPage outsideNavigation />;
  }

  return children;
}
