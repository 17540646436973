import type {
  Pipe17ObjectSyncTypes,
  LogicbrokerObjectSyncTypes,
  ShopifyObjectSyncTypes,
} from "./ObjectSyncsCard";

type ConnectionBase = {
  connector_id: string;
  display_name: string;
  id: string;
};

export type ShopifyConnection = ConnectionBase & {
  details: ShopifyDetails;
  source: "shopify";
};

export type Pipe17Connection = ConnectionBase & {
  details: Pipe17Details;
  source: "pipe17";
};

export type LogicbrokerConnection = ConnectionBase & {
  details: LogicbrokerDetails;
  source: "logic_broker";
};

export type Connection =
  | ShopifyConnection
  | Pipe17Connection
  | LogicbrokerConnection;

type AggregatorDetails = {
  auth_data: {
    apiKey: string;
  };
  connected_channels: number;
  connections: Array<{
    channel_mapping_id: string;
    custom_flows: Array<{
      description: string;
      name: string;
      published_at: Date | null;
    }>;
    source_name: string;
    uncompleted_orders: number;
  }>;
  default_priority: number;
  exclusion_tags: string[];
  id: string;
  inclusion_tags: string[];
  integration_metadata: {
    orgKey: string;
  };
  network_id: string;
  source: string;
  source_name: string;
  status: string;
  sync_types: string[];
  tenant_id: string;
  waiting_orders_sync_period_in_minutes: number;
};

type Pipe17Details = AggregatorDetails & {
  auth_data: {
    apiKey: string;
  };
};

type LogicbrokerDetails = AggregatorDetails & {
  auth_data: {
    subscription_key: string;
  };
};

type ShopifyDetails = {
  configuration: {
    allowed_financial_statuses: string[];
    custom_sku_mapping_field: null;
    exclusion_tags: string[];
    fulfillments_create_upstream: boolean;
    ignore_sku_regexes: string[];
    inclusion_tags: string[];
    inventory_sync_upstream: boolean;
    order_cancelled_tags: any[];
    order_created_downstream_tags: any[];
    order_hold_period_in_minutes: number;
    order_ingested_tags: any[];
    orders_cancel_downstream: boolean;
    orders_cancel_upstream: boolean;
    orders_create_downstream: boolean;
    products_create_downstream: boolean;
  };
  connected_at: Date;
  custom_flows: any[];
  display_name: string;
  enabled_at: string | null; // A null value means it's inactive
  location: {
    source_location_id: number;
  } | null;
  sales_channel: { channel_id: string } | null;
  shop_name: string;
  shopify_connection_id: string;
  shopify_locations: Array<{
    location_name: string;
    source_location_id: number;
  }>;
};

export const SUPPORTED_CONNECTION_TYPES = [
  "shopify",
  "pipe17",
  "logic_broker",
] as const;

export type ObjectSyncTypes =
  | Pipe17ObjectSyncTypes
  | LogicbrokerObjectSyncTypes
  | ShopifyObjectSyncTypes;
