import { Suspense } from "react";
import { createRoot } from "react-dom/client";

import { PageLoader } from "@stordco/fe-components";

import { App } from "./components/App";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Suspense fallback={<PageLoader />}>
    <App />
  </Suspense>,
);
